import React, { createContext } from 'react'
import { type UseFinanceiroInterface, useFinanceiro } from '../hooks/useFinanceiro'

export const FinanceiroContext = createContext<UseFinanceiroInterface>({} as any)

export const FinanceiroProvider = ({ children }): JSX.Element => {
  return (
    <FinanceiroContext.Provider value={{ ...useFinanceiro() }}>
        {children}
    </FinanceiroContext.Provider>
  )
}
