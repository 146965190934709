import { useContext, useEffect, useState } from 'react'
import { type CasesConsumptionI, getCasesConsumption, getInfo, type PricingParams, getPricing, type PricingInterface, type Fatura, getFaturas, getPagamentos, type PaymentInfo } from '../services'
import { type UserInfoResponse } from '../services/types'
import { NotificationContext } from '../context/NotificationContext'
import { ApiError } from '@prev-front/api-adapter'

export interface UseFinanceiroInterface {
  info: UserInfoResponse | undefined
  setInfo: any
  casesConsumption: CasesConsumptionI | undefined
  fetchInfo: () => Promise<void>
  pricing: PricingInterface | undefined
  faturas: Fatura[] | undefined
  pagamentos: PaymentInfo[] | undefined
}

export const useFinanceiro = (): UseFinanceiroInterface => {
  const [info, setInfo] = useState<UserInfoResponse | undefined>()
  const [casesConsumption, setCasesConsumption] = useState<CasesConsumptionI>()
  const [pricing, setPricing] = useState<PricingInterface | undefined>(undefined)
  const { api } = useContext(NotificationContext)
  const [faturas, setFaturas] = useState<Fatura[]>()
  const [pagamentos, setPagamentos] = useState<PaymentInfo[] | undefined>()

  const fetchInfo = async (): Promise<void> => {
    try {
      const info = await getInfo()
      setInfo(info)
    } catch(err) {
      console.log(err)
    }
  }

  const fetchCasesConsumption = async (): Promise<void> => {
    try {
      const casesConsumption = await getCasesConsumption()
      setCasesConsumption(casesConsumption)
    } catch (err) {
      api.error({
        message: err.message ?? "Algo deu errado ao pesquisar informações sobre consumo"
      })
    }
  }

  const fetchPricing = async (payload: PricingParams): Promise<void> => {
    try {
      const data = await getPricing(payload)
      setPricing(data)
    } catch (err) {
      if (err instanceof ApiError) {
        api.error({
          message: err.message,
          placement: 'topRight'
        })
      }
      if (!(err instanceof ApiError)) {
        api.error({
          message: 'Algo deu errado ao tentar carregar preço, tente novamente mais tarde'
        })
      }
    }
  }

  const fetchFaturas = async (): Promise<void> => {
    try {
      const data = await getFaturas()
      setFaturas(data)
    } catch (err) {
      if (err instanceof ApiError) {
        api.error({
          message: err.message,
          placement: 'topRight'
        })
      }
      if (!(err instanceof ApiError)) {
        api.error({
          message: 'Algo deu errado ao tentar carregar faturas, tente novamente mais tarde'
        })
      }
    }
  }

  const fetchPagamentos = async (): Promise<void> => {
    try {
      const pagamentosResponse = await getPagamentos()
      setPagamentos(pagamentosResponse)
    } catch (err) {
      api.error({
        message: err.message ?? 'Algo deu errado ao buscar pagamentos',
        placement: 'topRight'
      })
    }
  }

  useEffect(() => {
    void fetchInfo()
    void fetchCasesConsumption()
    void fetchFaturas()
    void fetchPagamentos()
  }, [])

  useEffect(() => {
    if (info) {
      void fetchPricing({
        intervalo: info.assinatura.intervalo,
        tipoDeEvento: 'ALTERACAO',
        tipoDeMeioDePagamento: info.assinatura.meioDePagamento,
        tiposDeModulo: info.assinatura.tiposDeModulo
      })
    }
  }, [info])

  return {
    info,
    setInfo,
    casesConsumption,
    fetchInfo,
    pricing,
    faturas,
    pagamentos
  }
}
