import { useEffect, useState } from 'react'
import { type UserObject, getAccountInfo } from '../services'

export interface UseAccountInfoInterface {
  accountInfo: UserObject & { ufOab?: string, numeroOabClean?: string } | undefined
  fetchAccountInfo: () => Promise<void>
}

export const useAccountInfo = (): UseAccountInfoInterface => {
  const [accountInfo, setAccountInfo] = useState<UserObject & { ufOab?: string, numeroOabClean?: string } | undefined>(undefined)

  const extrairEstado = (input): string | undefined => {
    const regex = /OAB\/([A-Z]+)/
    const match = input?.match(regex)
    if (match?.[1]) {
      return match[1]
    } else {
      return undefined
    }
  }

  const addDotAfterHundred = (oabNumber: string): string => {
    if (oabNumber?.length >= 4) {
      oabNumber = oabNumber.slice(0, -3) + '.' + oabNumber.slice(-3)
    }
    return oabNumber
  }

  const fetchAccountInfo = async (): Promise<void> => {
    const data = await getAccountInfo()
    const numeroOabClean = data.numeroDaOAB?.replace(/\D+/g, '') ? addDotAfterHundred(data.numeroDaOAB?.replace(/\D+/g, '')) : data.numeroDaOAB
    setAccountInfo({
      ...data,
      ufOab: extrairEstado(data.numeroDaOAB),
      numeroOabClean: numeroOabClean
    })
  }

  useEffect(() => {
    void fetchAccountInfo()
  }, [])

  return {
    accountInfo,
    fetchAccountInfo
  }
}
