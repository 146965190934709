import React, { useContext, useEffect, useState } from 'react'
import {
  Col,
  Form,
  type FormInstance,
  Input,
  Label,
  LocalLoading,
  Row,
  Spin,
  Text,
  usePrevTheme
} from '@prev-front/ui-components'
import Map, { Marker } from 'react-map-gl'
import 'mapbox-gl/dist/mapbox-gl.css'
import { CheckCircleOutlined, CloseCircleOutlined } from '@ant-design/icons'
import { findCoordinatesByZip } from '../../../services'
import { type CasosInterface } from '../../../services/types'
import moment from 'moment'
import { type CasosFormProps } from '../CasosView'
import { NotificationContext } from '../../../context/NotificationContext'
import { ApiError } from '@prev-front/api-adapter'

interface PersonalDataProps {
  data?: CasosInterface
  loading: boolean
  form: FormInstance<CasosFormProps>
  handleForm: (values: CasosFormProps) => Promise<void>
}

const { REACT_APP_MAPBOX_ACCESS_TOKEN } = process.env

export const PersonalData = (props: PersonalDataProps): JSX.Element => {
  const { theme } = usePrevTheme()
  const { data, loading, form, handleForm } = props
  const { api } = useContext(NotificationContext)
  const [mapCoordinates, setCoordinates] = useState({
    lat: undefined,
    long: undefined
  })
  const defaultMessage = `Olá ${data?.cliente.nome}!
Me chamo 908.166.070-52. Sou advogado(a) previdenciarista e tenho interesse em ajudar no seu caso perante o INSS!

Aguardo seu retorno para conversarmos melhor sobre o seu caso.

Att.
908.166.070-52
`

  const setupMap = async (endereco): Promise<void> => {
    try {
      if (!endereco) return
    const querys = Object.values(endereco)
      .map((value) => value)
      .join(' ')
    const response = await findCoordinatesByZip(querys)
    const { coordinates } = response.data.features[0].geometry
    setCoordinates({
      lat: coordinates[1],
      long: coordinates[0]
    })
    } catch (err: unknown) {
      api.error({
        message: err instanceof ApiError ? err.message : "Algo de errado ao consultar CEP do cliente",
        placement: "topRight"
      })
    }
  }

  useEffect(() => {
    if (!data) return
    void setupMap(data.cliente.endereco)
  }, [data])

  return (
    <Col>
      <LocalLoading
        display={loading}
        backgroundColor={theme.color.surface.default}
      />
      <Text emphasis>Dados livres do cliente</Text>
      <Row
        style={{
          width: '100%',
          justifyContent: 'space-between',
          marginBottom: '30px'
        }}
      >
        <Col>
          <Text color={theme.color.text.subtlest}>Nome completo</Text>
          <Text>{data?.cliente.nome}</Text>
        </Col>
        {data?.questionario.sexo && (
          <Col>
            <Text color={theme.color.text.subtlest}>Sexo</Text>
            <Text>
              {data?.questionario.sexo === 'MASCULINO'
                ? 'Masculino'
                : 'Feminino'}
            </Text>
          </Col>
        )}
        <Col>
          <Text color={theme.color.text.subtlest}>Nascimento</Text>
          <Text>
            {moment(data?.cliente.dataDeNascimento).format('DD/MM/YYYY')}
          </Text>
        </Col>
        <Col style={{ marginRight: '20px' }}>
          <Text color={theme.color.text.subtlest}>Local</Text>
          <Text>
            {data?.cliente.endereco.cidade} - {data?.cliente.endereco.uf}
          </Text>
        </Col>
      </Row>
      {loading && (
        <Row style={{ width: "100%", height: "400px"}} justify='center' align='middle'>
          <Spin />
        </Row>
      )}
      {mapCoordinates.lat && mapCoordinates.long && (
        <Map
          mapboxAccessToken={REACT_APP_MAPBOX_ACCESS_TOKEN}
          interactive={false}
          viewState={{
            longitude: mapCoordinates.long,
            latitude: mapCoordinates.lat,
            zoom: 5,
            width: 0,
            height: 0,
            bearing: 0,
            pitch: 0,
            padding: {
              bottom: 10,
              left: 10,
              right: 10,
              top: 10
            }
          }}
          attributionControl={false}
          style={{ width: '100%', height: 400 }}
          mapStyle="mapbox://styles/mapbox/streets-v9"
        >
          <Marker
            longitude={mapCoordinates.long}
            latitude={mapCoordinates.lat}
            color={theme.color.text.brand}
          />
        </Map>
      )}
      <Text emphasis style={{ marginTop: '30px', marginBottom: '20px' }}>
        Dados protegidos do cliente
      </Text>
      {!data?.reservado && (
        <Row style={{ gap: '20px' }}>
          <Col>
            <Text color={theme.color.text.subtlest}>Celular</Text>
            {data?.cliente.permiteContatoPorWhatsApp ? (
              <Text>
                Número verificado{' '}
                <CheckCircleOutlined
                  style={{ color: theme.color.icon.success }}
                />
              </Text>
            ) : (
              <Text>
                Não permite contato{' '}
                <CloseCircleOutlined
                  style={{ color: theme.color.icon.danger }}
                />
              </Text>
            )}
          </Col>
          <Col>
            <Text color={theme.color.text.subtlest}>E-mail</Text>
            {data?.cliente.email ? (
              <Text>
                E-mail verificado{' '}
                <CheckCircleOutlined
                  style={{ color: theme.color.icon.success }}
                />{' '}
              </Text>
            ) : (
              <Text>
                Não permite contato{' '}
                <CloseCircleOutlined
                  style={{ color: theme.color.icon.danger }}
                />
              </Text>
            )}
          </Col>
        </Row>
      )}
      {!data?.reservado &&
        data?.numeroDeReservas !== data?.quantidadeMaximaDeReservas && (
          <Form
            form={form}
            onFinish={handleForm}
            style={{ marginTop: '20px' }}
            scrollToFirstError={{
              behavior: 'smooth',
              block: 'center'
            }}
          >
            <Form.Item
              name="mensagemAdvogado"
              initialValue={defaultMessage}
              rules={[
                {
                  required: true,
                  message: 'A mensagem para contato é obrigatória'
                }
              ]}
            >
              <Label>
                Escreva ou envie o texto já preenchido
                <Input.TextArea
                  style={{
                    height: '250px',
                    color: theme.color.text.default,
                    backgroundColor: theme.color.surface.default
                  }}
                  defaultValue={defaultMessage}
                />
              </Label>
            </Form.Item>
          </Form>
        )}
      {data?.reservado && (
        <Row style={{ gap: '20px' }}>
          {data?.cliente.celular && (
            <Col>
              <Text color={theme.color.text.subtlest}>Celular</Text>
              <Text>{data.cliente.celular}</Text>
            </Col>
          )}
          {data?.cliente.email && (
            <Col>
              <Text color={theme.color.text.subtlest}>E-mail</Text>
              <Text>{data.cliente.email}</Text>
            </Col>
          )}
        </Row>
      )}
    </Col>
  )
}
