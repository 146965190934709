import React, { useContext, useEffect, useState } from 'react'
import {
  Col,
  type FormInstance,
  Text,
  Form,
  FieldInput,
  Row,
  Radio,
  Card,
  Label,
  Select,
  LocalLoading,
  usePrevTheme,
  UploadFile,
  notification,
  Avatar,
  useWidth
} from '@prev-front/ui-components'
import {
  FormAnimationContext,
  type UserProfile
} from '../../../context/FormAnimationContext'
import {
  ajustHifen,
  estados,
  estadosOab,
  profissoesUsuario
} from '../../../utils'
import { AccountInfoContext } from '../../../context/AccountInfoContext'
import {
  changeAccountInfo,
  cleanObject,
  getEnderecoByCep,
  patchUsuarioImage
} from '../../../services'
import { ApiError } from '@prev-front/api-adapter'
import { NotificationContext } from '../../../context/NotificationContext'
import moment from 'moment'

interface AccountInfoProps {
  form: FormInstance<UserProfile & { ufOab?: string; numeroOabClean?: string }>
}

export const AccountInfoForm = (props: AccountInfoProps): JSX.Element => {
  const { theme } = usePrevTheme()
  const { accountInfo, fetchAccountInfo } = useContext(AccountInfoContext)
  const { api } = useContext(NotificationContext)
  const { accountFormView } = useContext(FormAnimationContext)
  const [isLoading, setIsLoading] = useState(false)
  const [previewImage, setPreviewImage] = useState<string | undefined>()
  const [files, setFiles] = useState<any[any]>([])
  const [cellMask, setCellMask] = useState('(99) 99999-9999')
  const [phoneMask, setPhoneMask] = useState('(99) 99999-9999')
  const width = useWidth()
  const today = moment(Date.now()).format('YYYY-MM-DD')

  const fetchAddress = async (cep: string): Promise<void> => {
    try {
      const onlyNumbersCep = cep.replace(/\D/g, '')
      const endereco = await getEnderecoByCep(onlyNumbersCep)
      if(endereco.numero) delete endereco.numero
      props.form.setFieldsValue({ endereco })
    } catch (err: unknown) {
      console.log('erro fetchAddress:', err)
    }
  }

  const handleImageString = (file: {file: File}): void => {
    if (['image/jpeg', 'image/png'].includes(file?.file?.type)) {
      const reader = new FileReader()
      reader.onload = () => {
        setPreviewImage(reader?.result?.toString())
      }
      reader.readAsDataURL(file.file)
    } else {
      console.error('Selected file is not an image.')
    }
  }

  useEffect(() => {
    if (files.length > 0) {
      handleImageString(files[0])
    }
    if (files.length === 0) {
      setPreviewImage(undefined)
    }
  }, [files])

  const uploadImage = async (): Promise<void> => {
    if (!files.length) {
      return
    }

    const file = files[0].file // Acesso ao objeto File se estiver usando a biblioteca Ant Design Upload
    const formData = new FormData()
    formData.append('imagemDoPerfil', file) // O campo 'image' é onde você coloca o blob

    try {
      void patchUsuarioImage(formData)
    } catch (error: unknown) {
      api.error({ message: error instanceof ApiError ? error.message : 'Erro ao enviar imagem.' })
    }
  }

  const handleSubmit = async (
    values: UserProfile & { ufOab?: string; numeroOabClean?: string }
  ): Promise<void> => {
    try {
      setIsLoading(true)
      const payload = {
        ...values
      }
      if (values.profissao === 'ADVOGADO') {
        payload.numeroDaOAB = handleOabMask()
      }
      if (payload.ufOab) delete payload.ufOab
      if (payload.numeroOabClean) delete payload.numeroOabClean
      const payloadFinal = cleanObject(payload)
      await changeAccountInfo(payloadFinal)
      api.success({
        message: 'Dados atualizados com sucesso!',
        placemente: 'topRight'
      })
      void fetchAccountInfo()
      setIsLoading(false)
      if (previewImage) void uploadImage()
      accountFormView.setAccountForm(false)
    } catch (err) {
      setIsLoading(false)
      api.error({
        message: err instanceof ApiError ? err.message : 'Algo deu errado ao atualizar, tente novamente mais tarde',
        placemente: 'topRight'
      })
    }
  }

  const handleOabMask = (): string => {
    const uf = props.form.getFieldValue('ufOab')
    const numberOab = props.form
      .getFieldValue('numeroOabClean')
      ?.replace(/\D/g, '')
    const final = `OAB/${uf} ${addDotAfterHundred(numberOab)}`
    console.log('final', final)
    return final
  }

  const handleBeforeUpload = (file) => {
    const myNewFile = file
    const isImage = file.type.indexOf('image/') > -1
    if (!isImage) {
      notification.error({ message: 'Favor selecionar uma imagem!' })
    }
    const isLt2M = file.size / 1024 / 1024 < 2
    if (!isLt2M) {
      notification.error({ message: 'O arquivo deve ser menor que 2MB!' })
    }

    myNewFile.file = file

    if (isImage && isLt2M) {
      setFiles([myNewFile])
    }
    return false
  }

  const addDotAfterHundred = (oabNumber: string): string => {
    if (oabNumber?.length >= 4) {
      oabNumber = oabNumber.slice(0, -3) + '.' + oabNumber.slice(-3)
    }
    return oabNumber
  }

  useEffect(() => {
    console.log('account info', accountInfo)
    if(accountInfo) props.form.setFieldsValue(accountInfo)
    handleMasks()
    if (accountInfo?.urlImagemPerfil) void handleUserImage()
  }, [accountInfo])

  const handleUserImage = async (): Promise<void> => {
    try {
      if (!accountInfo?.urlImagemPerfil) return
      const parts = accountInfo?.urlImagemPerfil.split('/')
      const imageName = parts[parts.length - 1]
      const file: any = await fetchImageAsFile(
        accountInfo.urlImagemPerfil,
        imageName
      )
      if (!file) return
      file.file = file
      setFiles([file])
    } catch (err: unknown) {
      notification.error({
        message: err instanceof ApiError ? err.message : 'Algo deu errado ao tentar recuperar imagem de perfil',
        placement: 'topRight'
      })
    }
  }

  const handleMasks = (): void => {
    if(accountInfo?.telefone?.length === 15) setPhoneMask('(99) 99999-9999')
    if(accountInfo?.telefone?.length !== 15) setPhoneMask('(99) 9999-9999')
    if(accountInfo?.celular?.length === 15) setCellMask('(99) 99999-9999')
    if(accountInfo?.celular?.length !== 15) setCellMask('(99) 9999-9999')
  }

  async function fetchImageAsFile(
    url: string,
    filename: string
  ): Promise<File | null> {
    const response = await fetch(url)
    if (!response.ok) {
      throw new Error('Network response was not ok')
    }
    const blob = await response.blob()
    return new File([blob], filename, { type: blob.type })
  }

  if (isLoading)
    return (
      <LocalLoading
        display={isLoading}
        style={{ height: '100vh', overflow: 'hidden' }}
      />
    )

  return (
    <Row>
      <Col
        xs={24}
        sm={24}
        md={24}
        lg={24}
        xl={18}
        xxl={18}
        style={{
          padding: `0 20px 20px ${width <= 1200 ? '20px' : '130px'}`
        }}
      >
        <Text emphasis style={{ marginBottom: '40px' }} size="h6">
          Meus dados pessoais
        </Text>
        <Form
          form={props.form}
          onFinish={handleSubmit}
          style={{ marginTop: '30px' }}
        >
          <Row gutter={12} style={{ marginBottom: '30px' }}>
            <Col xs={24} sm={24} md={12} lg={12} xl={12} xxl={12}>
              <Form.Item
                name="nome"
                rules={[{ required: true, message: 'Digite seu nome' }]}
              >
                <FieldInput
                  label="Nome completo"
                  placeholder="Digite seu nome aqui"
                />
              </Form.Item>
            </Col>
            <Col xs={24} sm={24} md={6} lg={6} xl={6} xxl={6}>
              <Form.Item
                name="cpf"
                rules={[
                  {
                    pattern: /^(?:\d{3}\.?\d{3}\.?\d{3}-?\d{2}|\d{11})$/,
                    message: 'CPF inválido'
                  }
                ]}
              >
                <FieldInput
                  mask="999.999.999-99"
                  style={{ width: '100%' }}
                  label="CPF"
                  placeholder="Digite seu CPF aqui"
                />
              </Form.Item>
            </Col>
            <Col xs={24} sm={24} md={6} lg={6} xl={6} xxl={6}>
              <Label>
                <Text
                  color={theme.color.text.subtle}
                  style={{ fontSize: '14px' }}
                >
                  Sexo
                </Text>
                <Form.Item name="sexo">
                  <Radio.Group>
                    <Radio value="MASCULINO">Masculino</Radio>
                    <Radio value="FEMININO">Feminino</Radio>
                  </Radio.Group>
                </Form.Item>
              </Label>
            </Col>
          </Row>
          <Row gutter={12}>
            <Col xs={24} sm={24} md={6} lg={6} xl={6} xxl={6}>
              <Form.Item name="dataDeNascimento">
                <FieldInput
                  max={today}
                  type="date"
                  label="Data de nascimento"
                  placeholder="Digite sua data de nascimento"
                />
              </Form.Item>
            </Col>
            <Col xs={24} sm={24} md={12} lg={12} xl={12} xxl={12}>
              <Form.Item name="profissao">
                <Select
                  options={profissoesUsuario.map((profissao) => ({
                    label: profissao.desc,
                    value: profissao.id
                  }))}
                  label="Profissão"
                  placeholder="Digite sua profissão"
                />
              </Form.Item>
            </Col>
            <Col xs={24} sm={24} md={6} lg={6} xl={6} xxl={6}>
              <Form.Item shouldUpdate style={{ margin: 0, padding: 0 }}>
                {({ getFieldValue }) => {
                  const profissao = getFieldValue('profissao')
                  return (
                    profissao === 'ADVOGADO' && (
                      <Row gutter={12}>
                        <Col span={10}>
                          <Form.Item
                            name="ufOab"
                            rules={[
                              {
                                required: true,
                                message: 'Selecione o UF da OAB'
                              }
                            ]}
                          >
                            <Select
                              style={{ width: '100%' }}
                              options={estadosOab}
                              label="UF OAB"
                            />
                          </Form.Item>
                        </Col>
                        <Col span={14}>
                          <Form.Item
                            rules={[
                              {
                                message: 'Por favor, informe um número válido.',
                                required: true,
                                max: 7
                              }
                            ]}
                            name="numeroOabClean"
                          >
                            <FieldInput
                              onChange={(event) => {
                                props.form.setFieldValue(
                                  'numeroOabClean',
                                  addDotAfterHundred(
                                    event.target.value?.replace(/\D/g, '')
                                  )
                                )
                              }}
                              style={{ width: '100%' }}
                              maxLength={7}
                              label="Número OAB"
                            />
                          </Form.Item>
                        </Col>
                      </Row>
                    )
                  )
                }}
              </Form.Item>
            </Col>
          </Row>
          <Text
            emphasis
            size="h6"
            style={{ marginTop: '40px', marginBottom: '30px' }}
          >
            Meus dados de contato
          </Text>
          <Row gutter={12}>
            <Col xs={24} sm={24} md={12} lg={12} xl={12} xxl={12}>
              <Form.Item
                validateDebounce={500}
                name="email"
                rules={[
                  { required: true, message: 'O email é obrigatório' },
                  { type: 'email', message: 'Email inválido' }
                ]}
              >
                <FieldInput label="Email" placeholder="Digite seu email" />
              </Form.Item>
            </Col>
            <Col xs={24} sm={24} md={6} lg={6} xl={6} xxl={6}>
              <Form.Item
                validateDebounce={500}
                name="celular"
                rules={[
                  {
                    message: 'Número inválido',
                    min: 14
                  }
                ]}
              >
                <FieldInput
                  style={{ width: '100%' }}
                  mask={cellMask}
                  onKeyDown={(event) => {
                    const { currentTarget } = event
                    if (
                      currentTarget.value.length > 14 &&
                      event.key === 'Backspace'
                    ) {
                      setTimeout(() => {
                        setCellMask('(99) 9999-9999')
                        props.form.setFieldValue(
                          'celular',
                          ajustHifen(currentTarget.value)
                        )
                      }, 100)
                    }
                  }}
                  onBeforeInput={(event) => {
                    if (event.currentTarget.value.length >= 14) {
                      setCellMask('(99) 99999-9999')
                    } else {
                      setCellMask('(99) 9999-9999')
                    }
                  }}
                  label="Celular"
                  placeholder="Digite seu celular"
                />
              </Form.Item>
            </Col>
            <Col xs={24} sm={24} md={6} lg={6} xl={6} xxl={6}>
              <Form.Item
                validateDebounce={500}
                name="telefone"
                rules={[
                  {
                    message: 'Número inválido',
                    min: 14
                  }
                ]}
              >
                <FieldInput
                  style={{ width: '100%' }}
                  mask={phoneMask}
                  onKeyDown={(event) => {
                    const { currentTarget } = event
                    if (
                      currentTarget.value.length > 14 &&
                      event.key === 'Backspace'
                    ) {
                      setTimeout(() => {
                        setPhoneMask('(99) 9999-9999')
                        props.form.setFieldValue(
                          'telefone',
                          ajustHifen(currentTarget.value)
                        )
                      }, 100)
                    }
                  }}
                  onBeforeInput={(event) => {
                    if (event.currentTarget.value.length >= 14) {
                      setPhoneMask('(99) 99999-9999')
                    } else {
                      setPhoneMask('(99) 9999-9999')
                    }
                  }}
                  label="Telefone"
                  placeholder="Digite seu telefone"
                />
              </Form.Item>
            </Col>
          </Row>
          <Text
            size="h6"
            emphasis
            style={{ marginTop: '40px', marginBottom: '30px' }}
          >
            Meu endereço
          </Text>
          <Row gutter={12} style={{ marginBottom: '30px' }}>
            <Col span={6}>
              <Form.Item name={['endereco', 'cep']}>
                <FieldInput
                  mask="99999-999"
                  style={{ width: "100%"}}
                  onBlur={(event) => {
                    if (event?.target?.value.length >= 9) {
                      void fetchAddress(event.target.value)
                    }
                  }}
                  label="CEP"
                  placeholder="00000-000"
                />
              </Form.Item>
            </Col>
            <Col xs={24} sm={24} md={10} lg={10} xl={10} xxl={10}>
              <Form.Item name={['endereco', 'endereco']}>
                <FieldInput
                  label="Endereço"
                  placeholder="Digite o nome da rua ou avenida"
                />
              </Form.Item>
            </Col>
            <Col xs={24} sm={24} md={4} lg={4} xl={4} xxl={4}>
              <Form.Item name={['endereco', 'numero']}>
                <FieldInput label="Número" placeholder="Digite o número" />
              </Form.Item>
            </Col>
            <Col xs={24} sm={24} md={4} lg={4} xl={4} xxl={4}>
              <Form.Item name={['endereco', 'complemento']}>
                <FieldInput label="Complemento" placeholder="apt/sala/bloco" />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={12}>
            <Col xs={24} sm={24} md={10} lg={10} xl={10} xxl={10}>
              <Form.Item name={['endereco', 'bairro']}>
                <FieldInput label="Bairro" placeholder="Digite o bairro" />
              </Form.Item>
            </Col>
            <Col xs={24} sm={24} md={10} lg={10} xl={10} xxl={10}>
              <Form.Item name={['endereco', 'cidade']}>
                <FieldInput label="Cidade" placeholder="Digite a cidade" />
              </Form.Item>
            </Col>
            <Col xs={24} sm={24} md={4} lg={4} xl={4} xxl={4}>
              <Form.Item name={['endereco', 'uf']}>
                <Select
                  label="Estado"
                  placeholder="Selecione"
                  options={estados}
                />
              </Form.Item>
            </Col>
          </Row>
          <Row style={{ marginBottom: '30px' }}>
            <Col span={12}>
              <Text
                style={{
                  margin: '10px 0 5px 0',
                  fontSize: '14px',
                  color: theme.color.text.subtle
                }}
              >
                Foto de perfil
              </Text>
              <UploadFile
                beforeUpload={handleBeforeUpload}
                accept="image/*"
                multiple={false}
                sendMoreFilesButton={false}
                files={files}
                setFiles={setFiles}
              />
              <Row justify="center">
                {previewImage && (
                  <Avatar
                    src={previewImage}
                    size={160}
                    style={{ marginTop: '15px' }}
                  />
                )}
              </Row>
            </Col>
          </Row>
        </Form>
      </Col>
      <Col xs={0} sm={0} md={0} lg={0} xl={6} xxl={6}>
        <Card>
          <Text style={{ marginBottom: '20px' }} emphasis size="h6">
            Por que é importante manter o cadastro completo e atualizado?
          </Text>
          <Text
            style={{ marginBottom: '10px', color: theme.color.text.subtlest }}
          >
            Prev é a plataforma previdenciária mais moderna e mais segura do
            mercado!
          </Text>
          <Text
            style={{ marginBottom: '10px', color: theme.color.text.subtlest }}
          >
            Aqui seus dados são protegidos, além disso não usaremos seu email
            para enviar promoções, usaremos somente para envio dos emails
            transacionais e de atualizações da plataforma.
          </Text>
        </Card>
      </Col>
    </Row>
  )
}
