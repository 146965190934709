/* eslint-disable @typescript-eslint/no-dynamic-delete */
import { authGet, PrevAxios, getCookie, authGetWithHeader, authPut, get, post, authDelete, authPost, getToken } from '@prev-front/api-adapter'
import { API_HOST_DASHBOARD_V2, API_HOST_V1, REACT_APP_MAPBOX_ACCESS_TOKEN } from "./contants"
import { type OfficeInfoResponse, type CasosInterface, type UserInfoResponse } from './types'

import qs from 'qs'
import { type UserProfile } from '../context/FormAnimationContext'


export enum GroupByOptions {
  'DAY' = 'DIA',
  'WEEK' = 'SEMANA',
  'MONTH' = 'MES',
  'YEAR' = 'ANO',
}

export interface getDashboardReportsProps {
  type: string
  subType: string
  from?: string
  to?: string
  groupBy?: GroupByOptions
}

export const getDashboardReports = async (props: getDashboardReportsProps): Promise<DashboardReportsData[]> => {
  const { type, subType } = props
  const requestString = `/relatorios/${type}/criacao-${subType}?de=${props.from}&ate=${props.to}&agrupadoPor=${props.groupBy}`
  const data = await authGet(requestString, API_HOST_DASHBOARD_V2)
  return data
}

export interface DashboardReportsData {
  quantidade: number
  data: string
}

interface GetCasosProps {
  limite: number
  ordenarPor: string
  pagina?: number
  reservado?: boolean
}

interface GetCasosResponse {
  data: CasosInterface[]
  headers: any
}

export const getCasos = async (props: GetCasosProps): Promise<GetCasosResponse> => {
  const { limite, ordenarPor, pagina } = props
  const userJson = getToken()
  const cookie = getCookie('prev_session')
  let prev_session = '' as any
  if (cookie) prev_session = { 'X-Global-Session-User-ID': cookie }
  const data = await PrevAxios({
    method: 'GET',
    url: `${API_HOST_DASHBOARD_V2}/casos?limite=${limite}&ordenarPor=${ordenarPor}&pagina=${pagina}&reservado=${props.reservado}`,
    headers: {
      Authorization: `Bearer ${userJson}`,
      ...prev_session
    }
  })
  return {
    data: data?.data ?? [] as CasosInterface[],
    headers: data?.headers
  }
}

export const getCasoFull = async (id: string): Promise<CasosInterface> => {
  const userJson = getToken()
  const cookie = getCookie('prev_session')
  let prev_session = '' as any
  if (cookie) prev_session = { 'X-Global-Session-User-ID': cookie }
  const data = await PrevAxios({
    method: 'GET',
    url: `${API_HOST_DASHBOARD_V2}/casos/${id}`,
    headers: {
      Authorization: `Bearer ${userJson}`,
      ...prev_session
    }
  })
  return data.data as CasosInterface
}

interface ReserveCaseInterface {
  id: string
  description: string
}

export const reserveCase = async (props: ReserveCaseInterface): Promise<any> => {
  const { id, description } = props
  const cookie = getCookie('prev_session')
  let prev_session = '' as any
  if (cookie) prev_session = { 'X-Global-Session-User-ID': cookie }
  const token = getToken()
  const response = await PrevAxios({
    method: 'POST',
    url: `${API_HOST_DASHBOARD_V2}/casos/${id}/reservas`,
    headers: {
      Authorization: `Bearer ${token}`,
      'Content-Type': 'text/html',
      ...prev_session
    },
    data: description
  })
  return response as any
}

interface getPetitionsParams {
  params?: any
}

export const getPetitions = async ({
  params = 'status=REVISANDO&ordenaPor=SCORE&size=3'
}: getPetitionsParams): Promise<any> => {
  return await authGetWithHeader(`/peticoes?${params}`)
}

export const getPetitionFull = async ({
  id
}: {
  id: string
}): Promise<any> => {
  return await authGetWithHeader(`/peticoes/${id}`)
}

const { API_AUTH } = process.env

export const getSlug = async (slug: string): Promise<any> => {
  const data = await get(`/oauth/escritorios/${slug}`)
  return data
}

interface ForgotOfficeData {
  username: string
}

export const forgotOffice = async (data: ForgotOfficeData): Promise<any> => {
  const response = await post('/oauth/escritorio/recupera', data)
  return response
}

interface LoginData {
  grant_type: string
  slug: string
  username: string
  password: string
}

export const login = async (data: LoginData): Promise<any> => {
  const authApp = `Basic ${API_AUTH}`

  const payload = {
    grant_type: data.grant_type || 'password',
    slug: data.slug,
    username: data.username,
    password: data.password
  }

  const response = await post('/oauth/token', qs.stringify(payload), {
    headers: {
      Authorization: authApp,
      'Content-Type': 'application/x-www-form-urlencoded'
    }
  })
  return response
}

export const getUserInfo = async (): Promise<UserInfoResponse | null> => {
  const cookie = getCookie("prev_session");
  let prev_session = {};
  if (cookie) {
    prev_session = { "X-Global-Session-User-ID": cookie };
  }
  const response = await authGet('/info', API_HOST_DASHBOARD_V2, {
    headers: {
      ...prev_session
    }
  })
  return response as UserInfoResponse
}

export const updateOnboarding = async (values): Promise<void> => {
  await authPost({
    path: '/escritorios',
    data: values
  })
}

export const getInfo = async (): Promise<any> => {
  const cookie = getCookie("prev_session");
  let prev_session = {};
  if (cookie) {
    prev_session = { "X-Global-Session-User-ID": cookie };
  }
  const response = await authGet('/info', API_HOST_DASHBOARD_V2, {
    headers: {
      ...prev_session
    }
  })
  return response
}

interface Modulo {
  tipo: string
  quantidadeMinimaUsos: number
  quantidadeMaximoUsos: number
  quantidadeUtilizada: number
}

export interface CasesConsumptionI {
  inicio: string
  fim: string
  modulos: Modulo[]
}

export const getCasesConsumption = async (): Promise<CasesConsumptionI> => {
  const response = await authGet('/info/consumo', API_HOST_DASHBOARD_V2)
  return response as CasesConsumptionI
}

export const deleteSubscription = async (): Promise<any> => {
  const response = await authDelete({
    path: '/assinaturas'
  })
  return response
}

export interface PricingParams {
  intervalo: string
  tipoDeEvento: string
  tipoDeMeioDePagamento: string
  tiposDeModulo: string[]
}

interface Assinatura {
  intervalo: string
  dataDaProximaCobranca: string
  periodo: {
    inicio: string
    fim: string
    dataDaCobranca: string
  }
  tiposDeModulo: string[]
}

interface PrecosModulos {
  tipo: string
  valorBase: number
  porcentagemDesconto: number
  valorDesconto: number
  valorLiquido: number
  quantidadeMaximoUsos: number
  quantidadeMinimaUsos: number
}

interface Parcela {
  parcelas: number
  jurosPercentual: number
  valorTotal: number
  jurosValor: number
  valorDaParcela: number
}

interface TabelaDePrecoModulos {
  precosModulos: PrecosModulos[]
}

export interface PricingInterface {
  uuid: string
  valorBase: number
  valorDoDesconto: number
  valor: number
  assinatura: Assinatura
  tabelaDePrecoModulos: TabelaDePrecoModulos
  parcelas: Parcela[]
}

export const getPricing = async (params: PricingParams): Promise<PricingInterface> => {
  const reponse = await authPut({
    data: params,
    path: '/pricing',
    host: API_HOST_DASHBOARD_V2
  })

  return reponse as PricingInterface
}

interface Transacao {
  uuid: string
  codigoDeAutorizacao: string
  valor: number
  status: string
  tipo: string
  meioDePagamento: string
  mensagem: string
  codigoDeRespostaDoGateway: string
  mensagemDeRespostaDoGateway: string
  dataDeCriacao: string
}

interface Modulo {
  tipo: string
  valorBase: number
  valorDoDesconto: number
  valor: number
}

interface Periodo {
  id: number
  uuid: string
  inicio: string
  fim: string
  dataDaCobranca: string
  motivo: string
  tiposDeModulo: string[]
}

export interface Fatura {
  uuid: string
  status: string
  valorBase: number
  valorDoDesconto: number
  valor: number
  dataDoVencimento: string
  dataDoPagamento: string
  dataDeCriacao: string
  dataDeAtualizacao: string
  numeroDeParcelas: number
  possuiRecibo: boolean
  periodo: Periodo
  modulos: Modulo[]
  transacoes: Transacao[]
}

export const getFaturas = async (): Promise<Fatura[]> => {
  const response = await authGet('/assinatura/faturas', API_HOST_DASHBOARD_V2)
  return response
}

interface Usuario {
  id?: number
  uuid?: string
  nome?: string
  email?: string
  cpf?: string
}

interface Endereco {
  id?: number
  uf?: string
  cep?: string
  endereco?: string
  bairro?: string
  cidade?: string
  numero?: string
  complemento?: string
}

export interface UserObject {
  id?: number
  usuario?: Usuario
  nome?: string
  email?: string
  cpf?: string
  profissao?: string
  sexo?: string
  telefone?: string
  celular?: string
  dataDeNascimento?: string
  idade?: number
  endereco?: Endereco
  numeroDaOAB?: string
  privilegio?: string
  status?: string
  dataDoUltimoAcesso?: string
  urlImagemPerfil?: string
}

export const getAccountInfo = async (): Promise<UserObject> => {
  const data = await authGet('/contas/info')
  return data
}

interface Boleto {
  codigoDeBarras: string
  dataDeVencimento: string
  urlDeImpressao: string
}

export interface PaymentInfo {
  uuid: string
  uuidExterno: string
  valor: number
  status: string
  origemDoPagamento: string
  dataDeCriacao: string
  dataDeAtualizacao: string
  meioDePagamento: string
  boleto: Boleto
}

export const getPagamentos = async (): Promise<PaymentInfo[]> => {
  const data = await authGet('/pagamentos', API_HOST_DASHBOARD_V2)
  return data
}

interface PassChangeInterface {
  senha_atual: string
  nova_senha: string
}

export const changePassword = async (params: PassChangeInterface): Promise<void> => {
  await authPut({
    data: qs.stringify({
      senha_atual: params.senha_atual,
      senha_nova: params.nova_senha
    }),
    path: '/contas/senha'
  })
}

export const changeAccountInfo = async (payload: UserProfile): Promise<void> => {
  await authPut({
    data: payload,
    path: '/contas/info'
  })
}

export interface ChangeCardInterface {
  cvv: string
  nome: string
  numero: string
  bandeira: string
  dataDeVencimento: string
}

export const changeCreditCard = async (payload: ChangeCardInterface): Promise<void> => {
  await authPut({
    data: payload,
    path: '/assinaturas/info/cartaoDeCredito'
  })
}

export const getOfficeInfo = async (): Promise<OfficeInfoResponse> => {
  const response = await authGet('/escritorios/info')
  return response
}

export const patchSlug = async (slug: string): Promise<void> => {
  const token = getToken()
  await PrevAxios.patch(`${API_HOST_V1}/escritorios/info`, { slug }, {
    headers: {
      Authorization: `Bearer ${token}`
    }
  })
}

export const patchImageOffice = async (file: any): Promise<void> => {
  const token = getToken()
  const cookie = getCookie('prev_session')
  let prev_session = '' as any
  if (cookie) prev_session = { 'X-Global-Session-User-ID': cookie }
  await PrevAxios({
    method: 'PATCH',
    data: file,
    url: `${API_HOST_V1}/escritorios/imagem-escritorio`,
    headers: {
      Authorization: `Bearer ${token}`,
      ...prev_session
    }
  })
}

export const patchUsuarioImage = async (file: any): Promise<void> => {
  const token = getToken()
  const cookie = getCookie('prev_session')
  let prev_session = '' as any
  if (cookie) prev_session = { 'X-Global-Session-User-ID': cookie }
  await PrevAxios({
    method: 'PATCH',
    data: file,
    url: `${API_HOST_V1}/contas/imagem-perfil`,
    headers: {
      Authorization: `Bearer ${token}`,
      ...prev_session
    }
  })
}

export const removeOfficeImage = async (type: string): Promise<void> => {
  await authDelete({
    path: `/escritorios/imagem-escritorio/${type}`
  })
}

export const putOfficeInfo = async (payload: OfficeInfoResponse): Promise<void> => {
  await authPut({
    data: payload,
    path: '/escritorios/info'
  })
}

export const cleanObject = (obj: any): any => {
  for (const key in obj) {
    // Adiciona a condição para verificar strings vazias
    if (obj[key] === undefined || obj[key] === null || obj[key] === '') {
      delete obj[key]
    } else if (Array.isArray(obj[key])) {
      obj[key] = obj[key].filter((item: any) => {
        if (typeof item === 'object') {
          cleanObject(item)
          return Object.keys(item).length > 0
        }
        return item !== undefined && item !== null && item !== ''
      })
      if (obj[key].length === 0) {
        delete obj[key]
      }
    } else if (typeof obj[key] === 'object') {
      cleanObject(obj[key])
      if (Object.keys(obj[key]).length === 0) {
        delete obj[key]
      }
    }
  }
  return obj
}

export const validateCNPJ = (cnpj): boolean => {
  cnpj = cnpj.replace(/[^\d]+/g, '')
  if (cnpj === '') return false
  if (cnpj.length !== 14) return false

  // Elimina CNPJs invalidos conhecidos
  if (['00000000000000', '11111111111111', '22222222222222', '33333333333333',
    '44444444444444', '55555555555555', '66666666666666', '77777777777777',
    '88888888888888', '99999999999999'].includes(cnpj)) return false

  let tamanho = cnpj.length - 2
  let numeros = cnpj.substring(0, tamanho)
  const digitos = cnpj.substring(tamanho)
  let soma = 0
  let pos = tamanho - 7

  for (let i = tamanho; i >= 1; i--) {
    soma += numeros.charAt(tamanho - i) * pos--
    if (pos < 2) pos = 9
  }

  let resultado = soma % 11 < 2 ? 0 : 11 - soma % 11
  if (resultado !== parseInt(digitos.charAt(0))) return false

  tamanho = tamanho + 1
  numeros = cnpj.substring(0, tamanho)
  soma = 0
  pos = tamanho - 7
  for (let i = tamanho; i >= 1; i--) {
    soma += numeros.charAt(tamanho - i) * pos--
    if (pos < 2) pos = 9
  }
  resultado = soma % 11 < 2 ? 0 : 11 - soma % 11
  if (resultado !== parseInt(digitos.charAt(1))) return false

  return true
}

export const findCoordinatesByZip = async (zipcode: string): Promise<any> => {
  const data = await PrevAxios.get(`https://api.mapbox.com/geocoding/v5/mapbox.places/&types=postcode&country=BR&postcode=${zipcode}.json?access_token=${REACT_APP_MAPBOX_ACCESS_TOKEN}`)
  return data
}

export const getCourse = async (id: string): Promise<any> => {
  const response = await authGet(`/cursos/${id}`, API_HOST_DASHBOARD_V2)
  return response
}

export const getModules = async (id: string): Promise<any> => {
  const response = await authGet(`/cursos/${id}/modulos`, API_HOST_DASHBOARD_V2)
  return response
}

interface Curso {
  id: number
}

interface Modulo {
  id: number
  curso: Curso
}

export interface Aula {
  id: number
  modulo: Modulo
  uuid: string
  titulo: string
  descricao: string
  videoUrl: string
  posicao: number
}

export const getLessons = async (id: string): Promise<Aula[]> => {
  const response = await authGet(`/cursos/modulos/${id}/aulas`, API_HOST_DASHBOARD_V2)
  return response
}

const YT_URL = 'https://youtube.googleapis.com/youtube/v3'

export const fetchLastVideo = async (): Promise<any> => {
  try {
    const url = `${YT_URL}/activities`
    const { data } = await PrevAxios.get(url, {
      params: {
        part: 'snippet,contentDetails',
        channelId: 'UCn9YzkwOh82M9JChf1pWu5g',
        maxResults: 1,
        order: 'date',
        key: process.env.REACT_APP_YOUTUBE_KEY
      }
    })
    return data.items[0]
  } catch (e) {
    // return  {
    //   "kind": "youtube#activity",
    //   "etag": "FrbDuxEYA-WOohktQA4-wel7t9o",
    //   "id": "MTUxNjE2NTMzMjIyMTYxNjUzMzIyMjAwMDg4NQ",
    //   "snippet": {
    //       "publishedAt": "2021-03-23T21:00:22+00:00",
    //       "channelId": "UCn9YzkwOh82M9JChf1pWu5g",
    //       "title": "TEMA 709: Quais as condições para ter os efeitos suspensos sendo profissional da saúde?",
    //       "thumbnails": {
    //           "default": {
    //               "url": "https://i.ytimg.com/vi/xR9X-Gl87jg/default.jpg",
    //               "width": 120,
    //               "height": 90
    //           },
    //           "medium": {
    //               "url": "https://i.ytimg.com/vi/xR9X-Gl87jg/mqdefault.jpg",
    //               "width": 320,
    //               "height": 180
    //           },
    //           "high": {
    //               "url": "https://i.ytimg.com/vi/xR9X-Gl87jg/hqdefault.jpg",
    //               "width": 480,
    //               "height": 360
    //           },
    //           "standard": {
    //               "url": "https://i.ytimg.com/vi/xR9X-Gl87jg/sddefault.jpg",
    //               "width": 640,
    //               "height": 480
    //           },
    //           "maxres": {
    //               "url": "https://i.ytimg.com/vi/xR9X-Gl87jg/maxresdefault.jpg",
    //               "width": 1280,
    //               "height": 720
    //           }
    //       },
    //       "channelTitle": "Previdenciarista",
    //       "type": "upload"
    //   },
    //   "contentDetails": {
    //       "upload": {
    //           "videoId": "xR9X-Gl87jg"
    //       }
    //   }
    // }
  }
}

const WP_URL =
  'https://dnz6yh8i3gcjs.cloudfront.net/blog/wp-json/wp/v2/posts'

const handleResponse = async (response: any = [], key): Promise<any> => {
  const handled = await Promise.all(
    response.map((item) => {
      //   let imgSrc = null;

      //   if (key !== "decisoes") {
      //     if (
      //       item._links["wp:featuredmedia"] &&
      //       item._links["wp:featuredmedia"].length > 0
      //     ) {
      //       let mediaUrl = item._links["wp:featuredmedia"][0].href;
      //       mediaUrl = mediaUrl.replace(
      //         "https://previdenciarista.com",
      //         "https://cdn.previdenciarista.com"
      //       );
      //       imgSrc = await getThumbnail(mediaUrl);
      //     }
      //   }

      return {
        id: item.id,
        date: item.modified_gmt,
        link: `https://previdenciarista.com/blog/${item.slug}`,
        // link: item.link,
        title: item.title.rendered,
        imgSrc:
          item._embedded?.['wp:featuredmedia']?.['0'].source_url,
        author: item._embedded?.author[0].name,
        category: item._embedded?.['wp:term'][0][0].name
      }
    })
  )
  return handled
}

const getByCategory = async (category, key): Promise<any> => {
  const queryString = qs.stringify(
    {
      per_page: 1,
      categories: category,
      _embed: true
    },
    { addQueryPrefix: true, skipNulls: true, indices: false }
  )

  try {
    const res = await PrevAxios.get(`${WP_URL}${queryString}`)
    return res.status === 200 ? await handleResponse(res.data, key) : []
  } catch (e) {
    return []
  }
}

export const fetchLastBlogPost = async (): Promise<any> => {
  const [noticia] = await fetchBlogPosts()
  return noticia
}

export const fetchBlogPosts = async (): Promise<any> => {
  return await getByCategory('50,18', 'blog')
}

export const fetchNews = async (): Promise<any> => {
  return await getByCategory(18, 'noticias')
}

export const fetchDecisoes = async (): Promise<any> => {
  return await getByCategory(123, 'decisoes')
}

export const fetchColunas = async (): Promise<any> => {
  return await getByCategory(2, 'colunas')
}

export const getAulaHtml = async ({ moduloId, aulaId }): Promise<any> => {
  return await authGet(`/cursos/modulos/${moduloId}/aulas/${aulaId}/html`)
}

export const getEnderecoByCep = async (cep): Promise<any> => {
  return await authGet(`/utils/cep/${cep}`)
}

interface PostMemberInterface {
  email: string
  nome: string
  privilegio: string
}

export const postMember = async (payload: PostMemberInterface): Promise<any> => {
  await authPost({
    data: payload,
    path: '/membros'
  })
}


export const deleteMember = async (id: string): Promise<void> => {
  await authDelete({
    path: `/membros/${id}`
  })
}