import { PrevPlatformProvider, usePrevTheme } from '@prev-front/ui-components'
import React, { Suspense, useEffect } from 'react'
import { BrowserRouter } from 'react-router-dom'
import { NotificationProvider } from './context/NotificationContext'
import './reset.css'
import { Route, Routes } from 'react-router-dom'
import { AccountInfoProvider } from './context/AccountInfoContext'
import { EditOfficeProvider } from './context/EditOfficeContext'
import { FinanceiroProvider } from './context/FinanceiroContext'
import { FormAnimationProvider } from './context/FormAnimationContext'
import { UseSearchInterface } from '@prev-front/mf-template'
import { getToken } from '@prev-front/api-adapter'

const Home = React.lazy(
  () =>
    import(
      /* webpackChunkName: "dashboard-home" */ './pages/Home'
    ).then(module => ({ default: module.Home }))
)

const OfficeInfo = React.lazy(
  () =>
    import(
      /* webpackChunkName: "dashboard-officeInfo" */ './pages/OfficeInfo'
    ).then(module => ({ default: module.OfficeInfo }))
)

const UserAccount = React.lazy(
  () =>
    import(
      /* webpackChunkName: "dashboard-userAccount" */ './pages/UserAccount'
    ).then(module => ({ default: module.UserAccount }))
)

const FinanceiroView = React.lazy(
  () =>
    import(
      /* webpackChunkName: "dashboard-financeiro" */ './pages/Financeiro'
    ).then(module => ({ default: module.FinanceiroView }))
)

const Cursos = React.lazy(
  () =>
    import(
      /* webpackChunkName: "dashboard-cursos" */ './pages/Cursos'
    ).then(module => ({ default: module.Cursos }))
)

export interface RootProps {
  context: UseSearchInterface  & { scrollTop: () => void, checkPaywall: () => boolean, toggleDarkMode: () => void }
}


export default function Root (props: RootProps): JSX.Element {
  const { darkMode, theme } = usePrevTheme()

  useEffect(() => {
    document.body.style.backgroundColor = theme.color.surface.container.default
  }, [darkMode])

  const token = getToken()

  if (!token) {
    window.location.href = '/login'
    return <></>
  }

  return (
      <PrevPlatformProvider>
        <Suspense>
          <NotificationProvider>
            <BrowserRouter>
            <Routes>
              <Route
                path="/"
                element={
                    <FormAnimationProvider searchContext={props.context}>
                      <Home context={props.context}/>
                    </FormAnimationProvider>
                }
              />
              <Route
                path="/configuracoes"
                element={
                  <EditOfficeProvider>
                    <FormAnimationProvider searchContext={props.context}>
                        <OfficeInfo />
                    </FormAnimationProvider>
                  </EditOfficeProvider>
                }
              />
              <Route
                path="/configuracoes/conta"
                element={
                  <AccountInfoProvider>
                    <FormAnimationProvider searchContext={props.context}>
                        <UserAccount setLayoutDarkMode={props.context.toggleDarkMode} />
                    </FormAnimationProvider>
                  </AccountInfoProvider>
                }
              />
              <Route
                path="/configuracoes/financeiro"
                element={
                  <FinanceiroProvider>
                      <FinanceiroView />
                  </FinanceiroProvider>
                }
              />
              <Route
                path="/cursos"
                element={
                  <FinanceiroProvider>
                      <Cursos searchContext={props.context}/>
                  </FinanceiroProvider>
                }
              />
            </Routes>
            </BrowserRouter>
          </NotificationProvider>
        </Suspense>
      </PrevPlatformProvider>
  )
}
