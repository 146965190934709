// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

.ant-slider-handle-1 {
  display: none;
}
.ant-slider {
    width: 100%;
}
.ant-slider-track-2 {
  display: none;
}
.ant-slider-handle-2,
.ant-slider-handle-3 {
  display: flex;
  justify-content: center;
  width: 23px;
  height: 23px;
  line-height: 17px;
  border-width: 3px;
  top: 1.7%;
  cursor: default;
  p {
    font-size: 12px;
    font-weight: 700;
  }
}
.ant-slider-handle-2 {
  width: 12px;
  height: 12px;
  top: 5px;
}
.ant-slider-handle-2 p {
  display: none;
}
.ant-slider-handle-3 {
  border-color: #d3d3d3;
  background-color: #f7eded;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active,
input:-webkit-autofill:valid,
select:-webkit-autofill,
select:-webkit-autofill:hover,
select:-webkit-autofill:focus {
    -webkit-transition-delay: 99999s;
    border: transparent;
}

.ant-input-status-success {
  border: none;
}

.iCcscs.ant-input:focus:not(.ant-input-borderless), .iCcscs .ant-input-focused:not(.ant-input-borderless) {
  border: none;
}
`, "",{"version":3,"sources":["webpack://./src/reset.css"],"names":[],"mappings":"AAAA;IACI,SAAS;IACT,UAAU;IACV,sBAAsB;AAC1B;;AAEA;EACE,aAAa;AACf;AACA;IACI,WAAW;AACf;AACA;EACE,aAAa;AACf;AACA;;EAEE,aAAa;EACb,uBAAuB;EACvB,WAAW;EACX,YAAY;EACZ,iBAAiB;EACjB,iBAAiB;EACjB,SAAS;EACT,eAAe;EACf;IACE,eAAe;IACf,gBAAgB;EAClB;AACF;AACA;EACE,WAAW;EACX,YAAY;EACZ,QAAQ;AACV;AACA;EACE,aAAa;AACf;AACA;EACE,qBAAqB;EACrB,yBAAyB;AAC3B;;AAEA;;;;;;;;IAQI,gCAAgC;IAChC,mBAAmB;AACvB;;AAEA;EACE,YAAY;AACd;;AAEA;EACE,YAAY;AACd","sourcesContent":["* {\n    margin: 0;\n    padding: 0;\n    box-sizing: border-box;\n}\n\n.ant-slider-handle-1 {\n  display: none;\n}\n.ant-slider {\n    width: 100%;\n}\n.ant-slider-track-2 {\n  display: none;\n}\n.ant-slider-handle-2,\n.ant-slider-handle-3 {\n  display: flex;\n  justify-content: center;\n  width: 23px;\n  height: 23px;\n  line-height: 17px;\n  border-width: 3px;\n  top: 1.7%;\n  cursor: default;\n  p {\n    font-size: 12px;\n    font-weight: 700;\n  }\n}\n.ant-slider-handle-2 {\n  width: 12px;\n  height: 12px;\n  top: 5px;\n}\n.ant-slider-handle-2 p {\n  display: none;\n}\n.ant-slider-handle-3 {\n  border-color: #d3d3d3;\n  background-color: #f7eded;\n}\n\ninput:-webkit-autofill,\ninput:-webkit-autofill:hover,\ninput:-webkit-autofill:focus,\ninput:-webkit-autofill:active,\ninput:-webkit-autofill:valid,\nselect:-webkit-autofill,\nselect:-webkit-autofill:hover,\nselect:-webkit-autofill:focus {\n    -webkit-transition-delay: 99999s;\n    border: transparent;\n}\n\n.ant-input-status-success {\n  border: none;\n}\n\n.iCcscs.ant-input:focus:not(.ant-input-borderless), .iCcscs .ant-input-focused:not(.ant-input-borderless) {\n  border: none;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
