import React, { createContext, useState } from 'react'
import { useSpring, animated, type SpringValue } from 'react-spring'
import { Col, Form, LocalLoading, Row, usePrevTheme } from '@prev-front/ui-components'
import { Navbar } from '../components/ui/Navbar'
import { FormFooter } from '../components/ui/Footer'
import { AccountInfoForm } from '../components/conta/forms/ChangeAccountInfo'
import { EditOfficeForm } from '../components/editOffice/forms/EditOfficeForm'
import { CasosView } from '../components/casosview/CasosView'
import { tiposAjuda } from '../utils/respostasFicha'
import { NewTeamUserForm } from '../components/editOffice/forms/NewTeamUserForm'
import { UseSearchInterface } from '@prev-front/mf-template'

interface Endereco {
  cep: string
  endereco: string
  cidade: string
  complemento: string
  uf: string
  bairro: string
  numero: string
}

export interface UserProfile {
  profissao?: string
  nome?: string
  cpf?: string
  dataDeNascimento?: string
  sexo?: string
  email?: string
  telefone?: string
  celular?: string
  endereco?: Endereco
  numeroDaOAB?: string
}

export interface AnimationHandlerInterface {
  accountFormView: {
    isVisible: boolean
    setAccountForm: (props: boolean) => void
    accontFormX: SpringValue<number>
  }
  officeFormView: {
    isVisible: boolean
    setOfficeForm: (props: boolean) => void
    officeFormX: SpringValue<number>
  }
  casosView: {
    isVisible: boolean
    id: undefined | string
    setCasosView: (props: CasosViewInterface) => void
    casosViewX: SpringValue<number>
    title: undefined | string
  }
  teamView: {
    showTeamForm: boolean
    teamFormX: SpringValue<number>
    setShowTeamForm: (props: boolean) => void
  }
}

export interface CasosViewInterface {
  isVisible: boolean
  id: string | undefined
  title: undefined | string
}

export const FormAnimationContext = createContext<AnimationHandlerInterface>(
  {} as AnimationHandlerInterface
)

interface FormAnimationProviderProps {
  children: React.ReactNode
  searchContext: UseSearchInterface  & { scrollTop: () => void, checkPaywall: (module?:string) => boolean }
}

export const FormAnimationProvider = ({ children, searchContext }: FormAnimationProviderProps): JSX.Element => {
  const [accountForm, setAccountForm] = useState(false)
  const [form] = Form.useForm<UserProfile>()
  const [officeForm, setOfficeForm] = useState(false)
  const [showTeamForm, setShowTeamForm] = useState(false)
  const [loadingInvites, setLoadingInvites] = useState(false)
  const [teamForm] = Form.useForm()
  const [officeFormAntd] = Form.useForm()


  const [casosView, setCasosView] = useState<CasosViewInterface>({
    isVisible: false,
    id: undefined,
    title: undefined
  })

  const resizeObserver = new ResizeObserver(() => {
    window.outerWidth = window.innerWidth
  })

  resizeObserver.observe(document.documentElement)

  const { theme } = usePrevTheme()

  const { x: accontFormX } = useSpring({
    x: accountForm ? 0 : -window.outerWidth
  })

  const { x: officeFormX } = useSpring({
    x: officeForm ? 0 : -window.innerWidth
  })

  const { x: casosViewX } = useSpring({
    x: casosView.isVisible ? 0 : -window.innerWidth
  })

  const { x: teamFormX } = useSpring({
    x: showTeamForm ? 0 : -window.innerWidth
  })

  const value = {
    accountFormView: {
      isVisible: accountForm,
      accontFormX,
      setAccountForm
    },
    officeFormView: {
      isVisible: officeForm,
      officeFormX,
      setOfficeForm
    },
    casosView: {
      isVisible: casosView.isVisible,
      casosViewX,
      title: casosView.title,
      id: casosView.id,
      setCasosView
    },
    teamView: {
      showTeamForm,
      teamFormX,
      setShowTeamForm
    }
  }

  const onCloseCasosView = (): void => {
    setCasosView((prev) => {
      return {
        ...prev,
        isVisible: false,
        id: undefined
      }
    })
  }

  const onCloseAccountForm = (oldAccount: any): void => {
    setAccountForm(false)
    form.setFieldsValue(oldAccount)
  }

  const onCloseTeamForm = (): void => {
    setShowTeamForm(false)
  }

  const onCloseOfficeForm = (oldOffice: any): void => {
    setOfficeForm(false)
    officeFormAntd.setFieldsValue(oldOffice)
  }

  return (
    <FormAnimationContext.Provider value={value}>
      {children}
      <animated.div
        className="content"
        style={{
          transform: accontFormX.to((x) => `translate3d(${x * -1}px, 0, 0)`),
          position: 'fixed',
          display: accountForm ? 'block' : 'none',
          top: 0,
          left: 0,
          zIndex: 50,
          width: '100vw',
          height: '100vh',
          padding: '20px',
          backgroundColor: theme.color.surface.container.default,
          overflowY: 'scroll'
        }}
      >
        <Col>
          <Navbar
            page="ACCOUNT"
            onClose={onCloseAccountForm}
            title="Editar meu perfil"
          />
          <AccountInfoForm form={form} />
          <FormFooter page="ACCOUNT" form={form} onClose={onCloseAccountForm} />
        </Col>
      </animated.div>
      <animated.div
        className="content"
        style={{
          transform: officeFormX.to((x) => `translate3d(${x * -1}px, 0, 0)`),
          position: 'fixed',
          display: officeForm ? 'block' : 'none',
          top: 0,
          left: 0,
          zIndex: 50,
          width: '100vw',
          height: '100vh',
          padding: '20px',
          backgroundColor: theme.color.surface.container.default,
          overflowY: 'scroll'
        }}
      >
        <Col>
          <Navbar
            page="OFFICE"
            onClose={onCloseOfficeForm}
            title="Editar dados do escritório"
          />
          <EditOfficeForm form={officeFormAntd} />
          <FormFooter
            page="OFFICE"
            form={officeFormAntd}
            onClose={onCloseOfficeForm}
          />
        </Col>
      </animated.div>
      <animated.div
        className="content"
        style={{
          transform: casosViewX.to((x) => `translate3d(${x * -1}px, 0, 0)`),
          position: 'fixed',
          display: casosView.isVisible ? 'block' : 'none',
          top: 0,
          left: 0,
          zIndex: 50,
          width: '100vw',
          height: '100vh',
          padding: '20px',
          backgroundColor: theme.color.surface.container.default,
          overflowY: 'scroll'
        }}
      >
        <Col>
          <Navbar
            page="CASOS"
            title={
              tiposAjuda.find((e) => e.tipo === casosView.title)?.desc ?? ''
            }
            onClose={onCloseCasosView}
          />
          <CasosView searchContext={searchContext} />
        </Col>
      </animated.div>
      <animated.div
        className="content"
        style={{
          transform: teamFormX.to((x) => `translate3d(${x * -1}px, 0, 0)`),
          position: 'fixed',
          display: showTeamForm ? 'block' : 'none',
          top: 0,
          left: 0,
          zIndex: 50,
          width: '100vw',
          height: '100vh',
          padding: '20px',
          backgroundColor: theme.color.surface.container.default,
          overflowY: 'scroll'
        }}
      >
        <Navbar
          page="CASOS"
          title="Convidar equipe"
          onClose={onCloseTeamForm}
        />
        <Row
          style={{
            flexDirection: 'column',
            height: 'calc(100vh - 150px)',
            justifyContent: 'space-between'
          }}
        >
          <LocalLoading display={loadingInvites} />
          <NewTeamUserForm loading={loadingInvites} setLoading={setLoadingInvites} form={teamForm} />
          <FormFooter loading={loadingInvites} page="TEAM" form={teamForm} onClose={onCloseTeamForm} />
        </Row>
      </animated.div>
    </FormAnimationContext.Provider>
  )
}
