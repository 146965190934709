import React, { createContext } from 'react'
import { type UseAccountInfoInterface, useAccountInfo } from '../hooks/useAccountInfo'

export const AccountInfoContext = createContext <UseAccountInfoInterface>({} as any)

export const AccountInfoProvider = ({ children }): JSX.Element => {
  return (
    <AccountInfoContext.Provider value={{ ...useAccountInfo() }}>
        {children}
    </AccountInfoContext.Provider>
  )
}
