import React, { useContext, useEffect, useState } from 'react'
import {
  Col,
  type FormInstance,
  Text,
  Form,
  FieldInput,
  Row,
  Card,
  Select,
  LocalLoading,
  Tooltip,
  validateCnpj,
  usePrevTheme
} from '@prev-front/ui-components'
import { ajustHifen, estados, estadosOab } from '../../../utils'
import { EditOfficeContext } from '../../../context/EditOfficeContext'
import { NotificationContext } from '../../../context/NotificationContext'
import { type OfficeInfoResponse } from '../../../services/types'
import { cleanObject, getEnderecoByCep, putOfficeInfo } from '../../../services'
import { FormAnimationContext } from '../../../context/FormAnimationContext'

interface AccountInfoProps {
  form: FormInstance<
    OfficeInfoResponse & { ufOab?: string; numeroOabClean?: string }
  >
}

export const EditOfficeForm = (props: AccountInfoProps): JSX.Element => {
  const { theme } = usePrevTheme()
  const { office, fetchOfficeInfo } = useContext(EditOfficeContext)
  const [isLoading, setIsLoading] = useState(false)
  const { api } = useContext(NotificationContext)
  const { officeFormView } = useContext(FormAnimationContext)
  const [phoneMask, setPhoneMask] = useState('(99) 99999-9999')
  const [secondaryPhoneMask, setSecondaryPhoneMask] =
    useState('(99) 99999-9999')

  const fetchAddress = async (cep: string): Promise<void> => {
    try {
      const onlyNumbersCep = cep.replace(/\D/g, '')
      const endereco = await getEnderecoByCep(onlyNumbersCep)
      endereco.numero && delete endereco.numero
      props.form.setFieldsValue({ endereco })
    } catch (err) {}
  }

  const handleSubmit = async (
    values: OfficeInfoResponse & { ufOab?: string; numeroOabClean?: string }
  ): Promise<void> => {
    try {
      setIsLoading(true)
      const payload = {
        ...values
      }
      if (payload.ufOab && payload.numeroOabClean) {
        payload.numeroDaOAB = handleOabMask()
      }
      delete payload.ufOab
      delete payload.numeroOabClean
      const final = cleanObject(payload)
      await putOfficeInfo(final)
      void fetchOfficeInfo()
      setIsLoading(false)
      officeFormView.setOfficeForm(false)
      api.success({
        message: 'Escritório atualizado com sucesso',
        placement: 'topRight'
      })
    } catch (err) {
      setIsLoading(false)
      api.error({
        message: err?.message || 'Erro ao atualizar escritório',
        placement: 'topRight'
      })
    }
  }

  const handleOabMask = (): string => {
    const uf = props.form.getFieldValue('ufOab')
    const numberOab = props.form
      .getFieldValue('numeroOabClean')
      ?.replace(/\D/g, '')
    const final = `OAB/${uf} ${addDotAfterHundred(numberOab)}`
    return final
  }

  const addDotAfterHundred = (oabNumber: string): string => {
    if (oabNumber?.length >= 4) {
      oabNumber = oabNumber.slice(0, -3) + '.' + oabNumber.slice(-3)
    }
    return oabNumber
  }

  const cnpjValidator = async (_, value): Promise<any> => {
    if (!value || validateCnpj(value)) {
      await Promise.resolve()
      return
    }
    return await Promise.reject(new Error('CNPJ inválido!'))
  }

  useEffect(() => {
    office && props.form.setFieldsValue(office)
  }, [office])

  if (isLoading)
    return (
      <LocalLoading
        display={isLoading}
        style={{ height: '100vh', overflow: 'hidden' }}
      />
    )

  return (
    <Row>
      <Col
        sm={24}
        md={24}
        lg={24}
        xl={18}
        style={{
          padding: `0 20px 20px ${
            window.innerWidth <= 1200 ? '20px' : '130px'
          }`
        }}
      >
        <Text emphasis style={{ marginBottom: '40px' }} size="h6">
          Dados do escritório
        </Text>
        <Form form={props.form} onFinish={handleSubmit}>
          <Row gutter={12} style={{ marginBottom: '30px' }}>
            <Col span={12}>
              <Form.Item
                name="nome"
                rules={[
                  { required: true, message: 'Digite o nome do escritório' }
                ]}
              >
                <FieldInput
                  label="Nome do escritório"
                  placeholder="Digite nome do escritório aqui"
                />
              </Form.Item>
            </Col>
            <Col span={6}>
              <Tooltip
                title="Altere em configurações do escritório"
                placement="top"
              >
                <Form.Item name="slug">
                  <FieldInput
                    disabled
                    style={{ width: '100%' }}
                    label="Slug do escritório"
                    placeholder="Digite o slug aqui"
                  />
                </Form.Item>
              </Tooltip>
            </Col>
            <Col span={6}>
              <Form.Item
                name="cnpj"
                rules={[{ validator: cnpjValidator, message: 'CNPJ inválido' }]}
              >
                <FieldInput
                  mask="99.999.999/9999-99"
                  style={{ width: '100%' }}
                  label="CNPJ"
                  placeholder="Digite o CNPJ aqui"
                />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={12}>
            <Col span={10}>
              <Form.Item
                name="ufOab"
                rules={[{ required: true, message: 'Selecione o UF da OAB' }]}
              >
                <Select options={estadosOab} label="UF OAB" />
              </Form.Item>
            </Col>
            <Col span={14}>
              <Form.Item
                rules={[
                  {
                    message: 'Por favor, informe um número válido.',
                    required: true,
                    max: 7
                  }
                ]}
                name="numeroOabClean"
              >
                <FieldInput
                  onChange={(event) => {
                    props.form.setFieldValue(
                      'numeroOabClean',
                      addDotAfterHundred(event.target.value?.replace(/\D/g, ''))
                    )
                  }}
                  style={{ width: '100%' }}
                  maxLength={7}
                  label="Número OAB"
                />
              </Form.Item>
            </Col>
          </Row>
          <Text
            emphasis
            size="h6"
            style={{ marginTop: '40px', marginBottom: '30px' }}
          >
            Dados de contato do escritório
          </Text>
          <Row gutter={12}>
            <Col span={12}>
              <Form.Item
                validateDebounce={500}
                name="email"
                rules={[{ type: 'email', message: 'Email inválido' }]}
              >
                <FieldInput label="Email" placeholder="Digite o email" />
              </Form.Item>
            </Col>
            <Col span={6}>
              <Form.Item
                validateDebounce={500}
                name="telefone"
                rules={[
                  {
                    message: 'Número inválido',
                    min: 14
                  }
                ]}
              >
                <FieldInput
                  style={{ width: '100%' }}
                  mask={phoneMask}
                  onKeyDown={(event) => {
                    const { currentTarget } = event
                    if (
                      currentTarget.value.length > 14 &&
                      event.key === 'Backspace'
                    ) {
                      setTimeout(() => {
                        setPhoneMask('(99) 9999-9999')
                        props.form.setFieldValue(
                          'telefone',
                          ajustHifen(currentTarget.value)
                        )
                      }, 100)
                    }
                  }}
                  onBeforeInput={(event) => {
                    if (event.currentTarget.value.length >= 14) {
                      setPhoneMask('(99) 99999-9999')
                    } else {
                      setPhoneMask('(99) 9999-9999')
                    }
                  }}
                  label="Telefone"
                  placeholder="Digite seu telefone"
                />
              </Form.Item>
            </Col>
            <Col span={6}>
              <Form.Item
                validateDebounce={500}
                name="telefoneSecundario"
                rules={[
                  {
                    message: 'Número inválido',
                    min: 14
                  }
                ]}
              >
                <FieldInput
                  style={{ width: '100%' }}
                  mask={secondaryPhoneMask}
                  onKeyDown={(event) => {
                    const { currentTarget } = event
                    if (
                      currentTarget.value.length > 14 &&
                      event.key === 'Backspace'
                    ) {
                      setTimeout(() => {
                        setSecondaryPhoneMask('(99) 9999-9999')
                        props.form.setFieldValue(
                          'telefoneSecundario',
                          ajustHifen(currentTarget.value)
                        )
                      }, 100)
                    }
                  }}
                  onBeforeInput={(event) => {
                    if (event.currentTarget.value.length >= 14) {
                      setSecondaryPhoneMask('(99) 99999-9999')
                    } else {
                      setSecondaryPhoneMask('(99) 9999-9999')
                    }
                  }}
                  label="Telefone secundário"
                  placeholder="Digite o telefone"
                />
              </Form.Item>
            </Col>
          </Row>
          <Text
            size="h6"
            emphasis
            style={{ marginTop: '40px', marginBottom: '30px' }}
          >
            Endereço do escritório
          </Text>
          <Row gutter={12} style={{ marginBottom: '30px' }}>
            <Col span={6}>
              <Form.Item name={['endereco', 'cep']}>
                <FieldInput
                  mask="99999-999"
                  onBlur={(event) => {
                    if (event?.target?.value.length >= 9) {
                      void fetchAddress(event.target.value)
                    }
                  }}
                  label="CEP"
                  placeholder="00000-000"
                />
              </Form.Item>
            </Col>
            <Col span={10}>
              <Form.Item name={['endereco', 'endereco']}>
                <FieldInput
                  label="Rua/Avenida"
                  placeholder="Digite o nome da rua ou avenida"
                />
              </Form.Item>
            </Col>
            <Col span={4}>
              <Form.Item name={['endereco', 'numero']}>
                <FieldInput label="Número" placeholder="Digite o número" />
              </Form.Item>
            </Col>
            <Col span={4}>
              <Form.Item name={['endereco', 'complemento']}>
                <FieldInput label="Complemento" placeholder="apt/sala/bloco" />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={12}>
            <Col span={10}>
              <Form.Item name={['endereco', 'bairro']}>
                <FieldInput label="Bairro" placeholder="Digite o bairro" />
              </Form.Item>
            </Col>
            <Col span={10}>
              <Form.Item name={['endereco', 'cidade']}>
                <FieldInput label="Cidade" placeholder="Digite a cidade" />
              </Form.Item>
            </Col>
            <Col span={4}>
              <Form.Item name={['endereco', 'uf']}>
                <Select
                  label="Estado"
                  placeholder="Selecione"
                  options={estados}
                />
              </Form.Item>
            </Col>
          </Row>
        </Form>
      </Col>
      <Col sm={0} md={0} lg={0} xl={6}>
        <Card>
          <Text style={{ marginBottom: '20px' }} emphasis size="h6">
            Por que é importante manter o cadastro completo e atualizado?
          </Text>
          <Text
            style={{ marginBottom: '10px', color: theme.color.text.subtlest }}
          >
            Prev é a plataforma previdenciária mais moderna e mais segura do
            mercado!
          </Text>
          <Text
            style={{ marginBottom: '10px', color: theme.color.text.subtlest }}
          >
            Aqui seus dados são protegidos, além disso não usaremos seu email
            para enviar promoções, usaremos somente para envio dos emails
            transacionais e de atualizações da plataforma.
          </Text>
        </Card>
      </Col>
    </Row>
  )
}
