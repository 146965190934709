import { useEffect, useState } from 'react'
import { type OfficeInfoResponse } from '../services/types'
import { getOfficeInfo } from '../services'

export interface UseEditOfficeInterface {
  office: OfficeInfoResponse | null
  setOffice: (office: OfficeInfoResponse | null) => void
  fetchOfficeInfo: () => Promise<void>
}

export const useEditOffice = (): UseEditOfficeInterface => {
  const [office, setOffice] = useState<OfficeInfoResponse | null>(null)

  const ajustarHifen = (numero): string => {
    const posHifen = numero.indexOf('-')
    if (posHifen !== -1 && posHifen < numero.length - 1) {
      const semHifen = numero.replace('-', '')
      const novoNumero = semHifen.slice(0, posHifen + 1) + '-' + semHifen.slice(posHifen + 1)
      return novoNumero
    }
    return numero
  }

  const extrairEstado = (input): string | undefined => {
    const regex = /OAB\/([A-Z]+)/
    const match = input?.match(regex)
    if (match?.[1]) {
      return match[1]
    } else {
      return undefined
    }
  }

  const addDotAfterHundred = (oabNumber: string): string => {
    if (oabNumber?.length >= 4) {
      oabNumber = oabNumber.slice(0, -3) + '.' + oabNumber.slice(-3)
    }
    return oabNumber
  }

  const fetchOfficeInfo = async (): Promise<void> => {
    const response = await getOfficeInfo()
    const oldFormatRegex = /\(\d{2}\) \d{4}-\d{5}/
    const officePayload = {
      ...response,
      ufOab: extrairEstado(response.numeroDaOAB),
      numeroOabClean: response.numeroDaOAB?.replace(/\D+/g, '') ? addDotAfterHundred(response.numeroDaOAB?.replace(/\D+/g, '')) : response.numeroDaOAB,
      telefone: response.telefone && oldFormatRegex.test(response.telefone) ? ajustarHifen(response.telefone) : response.telefone,
      telefoneSecundario: response.telefoneSecundario && oldFormatRegex.test(response.telefoneSecundario) ? ajustarHifen(response.telefoneSecundario) : response.telefoneSecundario
    }
    setOffice(officePayload)
  }

  useEffect(() => {
    void fetchOfficeInfo()
  }, [])
  return {
    office,
    setOffice,
    fetchOfficeInfo
  }
}
