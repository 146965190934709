import React from 'react'
import * as ReactDOMClient from 'react-dom/client'
import singleSpaReact from 'single-spa-react'
import Root from './root.component'

const lifecycles = singleSpaReact({
  React,
  ReactDOMClient,
  rootComponent: Root,
  /* @ts-expect-error teste */
  errorBoundary (err) {
    console.log(err)
    return null
  }
})
export const { bootstrap, mount, unmount } = lifecycles
