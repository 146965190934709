import React, { useContext, useState } from 'react'
import {
  useWidth,
  Text,
  FieldInput,
  Col,
  Form,
  Button,
  Select,
  Row,
  usePrevTheme,
} from '@prev-front/ui-components'
import { DeleteOutlined, UserAddOutlined } from '@ant-design/icons'
import { NotificationContext } from '../../../context/NotificationContext'
import { postMember } from '../../../services'
import { FormAnimationContext } from '../../../context/FormAnimationContext'
import { EditOfficeContext } from '../../../context/EditOfficeContext'

interface Member {
  key: string
  nome: string
  email: string
  privilegio: string
}

interface NewTeamUserFormProps {
  form: any
  loading: boolean
  setLoading: (boolean) => void
}

export const NewTeamUserForm = (props: NewTeamUserFormProps): JSX.Element => {
  const { form, setLoading } = props
  const width = useWidth()
  const [members, setMembers] = useState<Member[]>([
    { key: Date.now().toString(), nome: '', email: '', privilegio: '' }
  ])
  const { api } = useContext(NotificationContext)
  const { theme } = usePrevTheme()
  const { teamView } = useContext(FormAnimationContext)
  const { fetchOfficeInfo } = useContext(EditOfficeContext)

  const addMember = (): void => {
    const newKey = Date.now()
    setMembers([
      ...members,
      { key: `${newKey}`, email: '', nome: '', privilegio: '' }
    ])
  }

  const removeMember = (index: number): void => {
    if (members.length > 1) {
      const newMembers = members.filter((_, i) => i !== index)
      setMembers(newMembers)
    }
  }

  const handleInputChange = (
    index: number,
    field: keyof Member,
    value: string
  ): void => {
    const newMembers = [...members]
    newMembers[index][field] = value
    setMembers(newMembers)
  }

  const handleSubmit = async (): Promise<void> => {
    setLoading(true)
    const invitesResult = await Promise.all(
      members.map(async (member) => {
        try {
          await postMember(member)
          return {
            error: false,
            message: ''
          }
        } catch (err) {
          return {
            error: true,
            message: `Algo deu errado ao enviar convite para: ${member.email}`
          }
        }
      })
    )
    setLoading(false)
    const failedInvites = invitesResult.filter((result) => result.error)
    if (failedInvites.length > 0) {
      if (failedInvites?.length === members?.length) {
        api.error({
          message: 'Não foi possivel enviar convites'
        })
        return
      }
      failedInvites.forEach((fail) => {
        api.error({
          message: fail.message,
          placement: 'topRight'
        })
      })
    }
    api.success({
      message: 'Convite enviado',
      placement: 'topRight'
    })
    setMembers([
      { key: Date.now().toString(), nome: '', email: '', privilegio: '' }
    ])
    void fetchOfficeInfo()
    teamView.setShowTeamForm(false)
  }

  return (
    <Col
      sm={24}
      md={24}
      lg={24}
      xl={18}
      style={{ padding: `20px 20px 20px ${width <= 1200 ? '20px' : '130px'}` }}
    >
      <Text emphasis size="h6" style={{ marginBottom: '20px' }}>
        Adicionar novos membros na equipe
      </Text>
      <Form form={form} onFinish={handleSubmit}>
        {members.map((member, index) => (
          <div key={member.key} style={{ marginBottom: '20px' }}>
            <Row gutter={12}>
              <Col span={6}>
                <Form.Item
                  name={['members', member.key, 'nome']}
                  rules={[
                    {
                      required: true,
                      message: 'Por favor, insira o nome completo'
                    }
                  ]}
                >
                  <FieldInput
                    placeholder="Nome completo"
                    value={member.nome}
                    onChange={(e) => {
                      handleInputChange(index, 'nome', e.target.value)
                    }}
                  />
                </Form.Item>
              </Col>
              <Col span={6}>
                <Form.Item
                  name={['members', member.key, 'email']}
                  rules={[
                    {
                      required: true,
                      message: 'Por favor, insira o e-mail',
                      type: 'email'
                    }
                  ]}
                >
                  <FieldInput
                    placeholder="E-mail"
                    value={member.email}
                    onChange={(e) => {
                      handleInputChange(index, 'email', e.target.value)
                    }}
                  />
                </Form.Item>
              </Col>
              <Col span={6}>
                <Form.Item
                  name={['members', member.key, 'privilegio']}
                  rules={[
                    {
                      required: true,
                      message: 'Por favor, selecione a permissão'
                    }
                  ]}
                >
                  <Select
                    placeholder="Selecione"
                    value={member.privilegio}
                    onChange={(value) => {
                      handleInputChange(index, 'privilegio', value)
                    }}
                    options={[
                      { label: 'Administrador', value: 'ADMINISTRADOR' },
                      { label: 'Membro', value: 'MEMBRO' }
                    ]}
                  />
                </Form.Item>
              </Col>
              <Col
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'center'
                }}
              >
                {members.length > 1 && (
                  <DeleteOutlined
                    style={{ color: 'red', fontSize: '16px' }}
                    onClick={() => {
                      removeMember(index)
                    }}
                  />
                )}
              </Col>
            </Row>
          </div>
        ))}
        <Button
          icon={<UserAddOutlined />}
          onClick={addMember}
          type="subtle"
          style={{ marginBottom: '20px', color: theme.color.text.brand }}
        >
          Adicionar mais um membro
        </Button>
      </Form>
    </Col>
  )
}
