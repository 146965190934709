import { notification } from '@prev-front/ui-components'
import React, { createContext } from 'react'

export const NotificationContext = createContext({} as any)

export const NotificationProvider = ({ children }): JSX.Element => {
  const [api, contextHolder] = notification.useNotification()
  return (
    <NotificationContext.Provider value={{
      api,
      contextHolder
    }}>
        {children}
        {contextHolder}
    </NotificationContext.Provider>
  )
}
