import React, { createContext } from 'react'
import { type UseEditOfficeInterface, useEditOffice } from '../hooks/useEditOffice'

export const EditOfficeContext = createContext<UseEditOfficeInterface>({} as any)

export const EditOfficeProvider = ({ children }): JSX.Element => {
  const value = useEditOffice()
  return (
    <EditOfficeContext.Provider value={{ ...value }}>
        {children}
    </EditOfficeContext.Provider>
  )
}
