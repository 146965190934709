import React from 'react'
import { Col, Switch, Text, usePrevTheme } from '@prev-front/ui-components'
import { type CasosInterface } from '../../../services/types'
import {
  TEXT_CASES_TYPES,
  TIPOS_BENEFICIOS_CALCULO
} from '@prev-front/api-adapter'
import {
  familiar,
  historicoOpcoesHomem,
  historicoOpcoesMulher
} from '../../../utils/respostasFicha'

interface ClientCaseProps {
  data?: CasosInterface
}

export const ClientCaseSheet = (props: ClientCaseProps): JSX.Element => {
  const { theme } = usePrevTheme()

  return (
    <Col style={{ display: 'flex', flexDirection: 'column', gap: '10px' }}>
      <Text>Detalhes do caso</Text>
      <Text emphasis color={theme.color.text.subtle}>
        Tipo do caso
      </Text>
      <Text>
        {
          TEXT_CASES_TYPES.find(
            (casoAjuda) =>
              casoAjuda.tipo === props.data?.questionario?.tipoDeAjuda
          )?.desc
        }
      </Text>
      {props.data?.questionario.grauDeParentesco && (
        <>
          <Text emphasis color={theme.color.text.subtle}>
            Grau de parentesco
          </Text>
          <Text>
            {
              familiar.find(
                (e) => e.tipo === props.data?.questionario?.grauDeParentesco
              )?.desc
            }
          </Text>
        </>
      )}
      {props.data?.questionario.historicoDoSegurado && (
        <>
          <Text emphasis color={theme.color.text.subtle}>
            Histórico
          </Text>
          <Text>
            {props.data?.questionario.sexo === 'MASCULINO'
              ? historicoOpcoesHomem.find(
                  (e) =>
                    e.tipo === props.data?.questionario?.historicoDoSegurado
                )?.desc
              : historicoOpcoesMulher.find(
                  (e) =>
                    e.tipo === props.data?.questionario?.historicoDoSegurado
                )?.desc}
          </Text>
        </>
      )}
      <Text style={{ marginBottom: '15px' }}>
        {
          TIPOS_BENEFICIOS_CALCULO.find(
            (e) => e.value === props.data?.questionario?.beneficioAntigo
          )?.label
        }
      </Text>
      <Text emphasis color={theme.color.text.subtle}>
        Já realizou peido e concessão do benefício
      </Text>
      <Switch
        /* @ts-expect-error-error error ui kit nao remover */
        style={{ width: '50px' }}
        checked={props.data?.questionario?.pedidoDeConcessao}
      />
      <Text emphasis color={theme.color.text.subtle}>
        Já realizou pedido para revisar o benefício no INSS
      </Text>
      <Switch
        /* @ts-expect-error-error error ui kit nao remover */
        style={{ width: '50px' }}
        checked={props.data?.questionario?.pedidoDeRevisao}
      />
      <Text emphasis color={theme.color.text.subtle}>
        Já contribuiu para Previdência Social?
      </Text>
      <Switch
        /* @ts-expect-error-error error ui kit nao remover */
        style={{ width: '50px' }}
        checked={props.data?.questionario?.jaContribuiuParaPrevidenciaSocial}
      />
      <Text
        emphasis
        color={theme.color.text.subtle}
        style={{ marginTop: '20px', marginBottom: '10px' }}
      >
        Detalhes do caso pelo cliente
      </Text>
      <Text>{props.data?.descricao}</Text>
    </Col>
  )
}
