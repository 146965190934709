import { Row, Text, Button, usePrevTheme } from '@prev-front/ui-components'
import React, { useContext } from 'react'
import { ArrowLeftOutlined } from '@ant-design/icons'
import { AccountInfoContext } from '../../context/AccountInfoContext'
import { EditOfficeContext } from '../../context/EditOfficeContext'

interface Props {
  onClose: any
  title: string
  buttons?: [any]
  page: string
}

export const Navbar = (props: Props): JSX.Element => {
  const { onClose, title, buttons, page } = props
  const { theme } = usePrevTheme()
  const { accountInfo } = useContext(AccountInfoContext)
  const { office } = useContext(EditOfficeContext)

  return (
    <Row
      style={{
        justifyContent: 'space-between',
        margin: 0,
        padding: '40px 20px',
        marginBottom: '20px',
        width: '100%',
        backgroundColor: theme.color.surface.container.default,
        zIndex: 50,
        height: '100px'
      }}
    >
      <Row
        style={{ gap: '20px', justifyContent: 'center', alignItems: 'center' }}
      >
        <Button
          type="subtle"
          onClick={() => onClose(page === 'OFFICE' ? office : accountInfo)}
        >
          <Row style={{ gap: '5px' }}>
            <ArrowLeftOutlined />
            <Text>Voltar</Text>
          </Row>
        </Button>
        <Text size="h4" style={{ fontWeight: 600}}>{title}</Text>
      </Row>
      {buttons?.map((e) => e)}
    </Row>
  )
}
